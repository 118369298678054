import { render, staticRenderFns } from "./VehicleCategoryForm.vue?vue&type=template&id=81f82d9c&scoped=true&"
import script from "./VehicleCategoryForm.vue?vue&type=script&lang=js&"
export * from "./VehicleCategoryForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81f82d9c",
  null
  
)

export default component.exports