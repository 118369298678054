<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="info" v-if="paramsId">
        <span class="mr-20">所属门店：{{form.shopName}}</span>
        <span class="mr-20">状态：{{form.status ? '禁用' : '启用'}}</span>
        <span class="mr-20">创建时间：{{form.createTime}}</span>
      </div>
      <div slot="content">
        <a-card title="车辆类型">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="类型名称" prop="kartClassifyName">
                  <a-input v-model="form.kartClassifyName" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="类型编码" prop="kartClassifyCode">
                  <a-input v-model="form.kartClassifyCode" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="车辆规格" prop="kartClassifySpec">
                  <a-input v-model="form.kartClassifySpec" type="textarea" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="数据标签" prop="dataLabel">-->
<!--                  <a-input v-model="form.dataLabel" type="textarea" :disabled="isDetail"></a-input>-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="数据标签" prop="dictCategoryId">
                  <j-category-dict-select
                    placeholder="请选择标签"
                    v-model="form.dictCategoryId"
                    :disabled="isDetail"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="车辆类别" prop="carCategoryId">
                  <j-category-car-select
                    placeholder="请选择分类"
                    v-model="form.carCategoryId"
                    :disabled="isDetail"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="类别展示颜色" prop="color">
                  <a-select v-model="form.color" allowClear :disabled="isDetail">
<!--                    蓝色 #409dff-->
<!--                    橙色 #ff6633-->
<!--                    黄色 #fec22b-->
<!--                    青色 #24c5c0-->
<!--                    绿色 #45be8c-->
<!--                    紫色 #ae6fda-->
                    <a-select-option value="#409dff">
                      蓝色
                    </a-select-option>
                    <a-select-option value="#ff6633">
                      橙色
                    </a-select-option>
                    <a-select-option value="#fec22b">
                      黄色
                    </a-select-option>
                    <a-select-option value="#24c5c0">
                      青色
                    </a-select-option>
                    <a-select-option value="#45be8c">
                      绿色
                    </a-select-option>
                    <a-select-option value="#ae6fda">
                      紫色
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="有效类型" prop="periodType">
                  <a-select v-model="form.periodType" allowClear :disabled="isDetail">
                    <a-select-option :value="0">
                      长期
                    </a-select-option>
                    <a-select-option :value="1">
                      自定义
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-if="form.periodType === 1">
              <a-col :span="10">
                <a-form-model-item label="有效时间" prop="duration">
                  <a-range-picker
                    v-model="form.duration"
                    class="x-inner"
                    format="YYYY-MM-DD"
                    :disabled="isDetail"
                  ></a-range-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { vehicleCategoryInfo, vehicleCategorySubmit } from '@/api/vehicle';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';
import { formatDate, toMoment } from '@/libs/utils';
import JCategoryCarSelect from "@/components/JCategoryCarSelect";
import JCategoryDictSelect from "@/components/JCategoryDictSelect";
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil';
import { loadCategoryDictData } from '@/api/manage';
export default {
  name: 'vehicleCategoryForm',
  mixins: [formMixin],
  components: {
    FormFrame,
    JCategoryCarSelect,
    JCategoryDictSelect
  },
  props: {},
  data() {
    return {
      form: {
        kartClassifyName: '',
        kartClassifyCode: '',
        kartClassifySpec: '',
        dataLabel: '',
        periodType: 0,
        duration: [],
        carCategoryId:null,
        dictCategoryId:null,
        color:'#409dff'
      },
      dictOptions: {},
      rules: {
        kartClassifyName: [
          {
            required: true,
            message: '请输入类型名称'
          }
        ],
        kartClassifyCode: [
          {
            required: true,
            message: '请输入类型编码'
          }
        ],
        periodType: [
          {
            required: true,
            message: '请选择规则期限'
          }
        ],
        duration: [
          {
            required: true,
            message: '请选择有效时间',
          }
        ],
      },
    };
  },
  computed: {
  },
  watch: {},
  created() {
    this.initDictConfig();
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 会员信息
    getData(id) {
      vehicleCategoryInfo(id).then((data) => {
        data.duration = [];
        this.form = data;
        this.form.duration = [toMoment(data.startDate), toMoment(data.endDate)];
      });
    },

    // 保存
    handleSave() {
      const params = { ...this.form };
      if(params.dictCategoryId){
        params.dataLabel = filterMultiDictText(this.dictOptions.dictCategoryId, params.dictCategoryId);
      }
      if (params.duration && params.duration.length) {
        params.startDate = formatDate(params.duration[0]);
        params.endDate = formatDate(params.duration[1]);
      }
      delete params.duration;
      vehicleCategorySubmit(params).then(() => {
        this.$message.success('操作成功！');
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
    initDictConfig() {
      loadCategoryDictData({ code: '0' }).then((res) => {
        // console.log(res)
        if (res !== undefined) {
          this.$set(this.dictOptions, 'dictCategoryId', res);
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
